.birthday-card-container {
  text-align: center;
  padding: 20px;
  background-color: #ffe5ec;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.proceed-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.3s;
}

.proceed-button:hover {
  background-color: #218838;
}

.birthday-card-container {
  text-align: center;
  padding: 20px;
  background-color: #ffe5ec;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-height: 100vh; 
  overflow-y: auto; 
}

.birthday-card-container h1 {
  margin-top: 20px;
  font-size: 2rem;
}

.birthday-card-container p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}