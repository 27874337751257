@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=SF+Pro+Text:wght@400;500&display=swap');

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 100vh;
  background: linear-gradient(to bottom right, white, #ffa8a8);;
}

.hello-heading {
  position: absolute;
  top: 20px;
  left: 200px; /* Adjusted to align with the first information box */
  font-size: 2.5em;
  font-family: 'SF Pro Text', sans-serif;
  color: #333;
}

.alina-gradient {
  background: -webkit-linear-gradient(#f0c27b, #4b1248);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.login-form {
  margin-top: 100px;
  width: 100%;
  max-width: 250px; /* Adjusted max-width for smaller form */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
}

.login-input {
  width: 100%; /* Adjust width */
  padding: 8px 12px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 0.9em;
  font-family: 'SF Pro Text', sans-serif;
  transition: all 0.3s ease;
}

.login-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.2);
  outline: none;
}

.login-input-label {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  background-color: white;
  padding: 0 5px;
  color: #999;
  font-size: 0.9em;
  pointer-events: none;
  transition: all 0.3s ease;
}

.login-input:focus + .login-input-label,
.login-input:not(:placeholder-shown) + .login-input-label {
  top: -10px;
  left: 8px;
  font-size: 0.75em;
  color: #007bff;
}

.login-button {
  background-color: #007bff;
  color: white;
  padding: 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  font-family: 'SF Pro Text', sans-serif;
  transition: background-color 0.3s ease;
  width: 50px; /* Adjusted width for smaller button */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px; /* Space between input fields and button */
  margin-left: 10px; /* Move button to the right */
}

.login-button:hover {
  background-color: #0056b3;
}

.instructions {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
  gap: 20px;
}

.instruction-box {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1;
  text-align: left; /* Aligned text to the left */
  font-family: 'SF Pro Text', sans-serif;
  box-sizing: border-box;
}

.instruction-box h2 {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.instruction-box p {
  margin: 0;
  font-size: 1em;
  color: #555;
}
