.split-container {
  display: flex;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.left, .right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left {
  background: linear-gradient(120deg, #fff7e0 0%, #ffecb3 50%, #ffe082 100%);
  flex-direction: column;
  padding: 40px;
  height: 100%;
  justify-content: flex-start;
}

.typing-effect {
  font-size: 1.5em;
  color: #333;
  white-space: pre-wrap;
  border-right: none;
}

.right {
  background-color: #333;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.right video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.next-page-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.next-page-button:hover {
  background-color: #0056b3;
}
