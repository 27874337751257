.alina-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to bottom right, #ffffff, #a8c0ff);
}

.image-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fixed-image {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
}

.speech-bubble {
  position: absolute;
  top: 20%;
  right: -150px;
  width: 250px;
  padding: 15px;
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 1em;
  color: #333;
  text-align: center;
}

.speech-text {
  margin: 0;
}

.fixed-navigation {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.prev-button,
.next-button {
  background-color: #007bff;
  border: none;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.prev-button:hover,
.next-button:hover {
  background-color: #0056b3;
}

.navigate-button {
  margin-top: 20px;
  background-color: #28a745;
  border: none;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.navigate-button:hover {
  background-color: #218838;
}
