.video-credits-container {
  display: flex;
  height: 100vh; /* Ensure the height is 100% of the viewport */
  background: linear-gradient(to bottom right, #ffffff, #a8c0ff); /* Maintain the gradient background */
  overflow: hidden; /* Prevent scrolling */
  padding: 20px; /* Add padding to ensure containers do not touch the viewport borders */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  gap: 20px; /* Add gap between video and text box */
}

.video-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box; /* Include padding in the element's total width and height */
}

.rounded-video {
  width: 100%; /* Ensure the video takes full width of its wrapper */
  max-width: 500px; /* Keep the increased max width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 20px; /* Rounded corners */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white; /* Background to show the rounded corners */
  border-radius: 20px; /* Rounded corners */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add some shadow for better visibility */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  max-width: 100%; /* Allow the text container to expand */
  height: calc(100vh - 40px); /* Ensure it fills the space within the container */
}

.text-container h2 {
  font-size: 2em;
  margin-bottom: 10px;
}

.text-container p {
  font-size: 1.2em;
}
